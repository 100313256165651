<template>
  <l-feature-group>
    <detection-marker
      v-if="detectionObject"
      v-bind="{ ...detectionObject, activeKey, level: position.level }"
      :selected="detectionObject.correlation_key === activeKey"
      @selectDetection="onSelect"
      :marker-number="detectionNumber"
    >
      <detection-popup
        id="intersection-popup"
        slot="popup"
        :tracking-button="true"
        v-bind="detection"
        type="rf"
      ></detection-popup>
    </detection-marker>
    <origin-marker
      data-testid="track-origin-marker"
      v-if="tail[0]"
      :position="tail[0]"
      @originClicked="onSelect"
      :marker-number="detectionNumber"
      :selected="detection.correlation_key === activeKey"
      :state="detection.state"
    />
    <l-polyline
      :visible="
        detection.correlation_key === activeKey && position !== undefined
      "
      :opacity="1"
      color="red"
      :fill-opacity="0"
      :weight="2"
      :lat-lngs="tail"
      :smooth-factor="2"
    />
  </l-feature-group>
</template>
<script>
import { DetectionMarker, OriginMarker } from '../Marker'
import DetectionPopup from './DetectionPopup'
import { LFeatureGroup, LPolyline } from 'vue2-leaflet'
import { mapGetters } from 'vuex'

const props = {
  origin: {
    type: Object
  },
  detection: {
    type: Object
  },
  activeKey: {
    type: [Number, String],
    default: null
  },
  detectionNumber: {
    type: Number
  },
  isCameraDetectingRF: {
    type: Boolean,
    default: false
  }
}

// DEPRECATED
export default {
  name: 'IntersectionDetection',
  components: {
    DetectionMarker,
    OriginMarker,
    DetectionPopup,
    LFeatureGroup,
    LPolyline
  },
  props,
  data: () => ({
    tail: [],
    firstDetection: null,
    detectionObject: null,
    watcher: null
  }),
  computed: {
    ...mapGetters('cameras', ['cameraTrackedTarget']),
    ...mapGetters('sites', ['activeSite']),
    isDrone() {
      return !(
        this.detection.protocol && this.detection.protocol.includes('FHSS')
      )
    },
    isCameraTracked() {
      return (
        this.isDrone &&
        this.isCameraDetectingRF &&
        this.cameraTrackedTarget === this.detection.correlation_key
      )
    },
    position() {
      const { detection } = this
      const { latitude, longitude } = detection || {}
      return {
        position: [latitude, longitude],
        level: `${this.isDrone ? 2 : 3}${this.isCameraTracked ? '-5' : ''}`
      }
    }
  },
  methods: {
    onSelect() {
      const { correlation_key } = this.detection
      this.$emit('selectDetection', correlation_key)
    }
  },
  watch: {
    detection(v) {
      if (v) {
        if (!this.firstDetection) {
          this.firstDetection = v
          this.detectionObject = v
        } else if (
          this.detectionObject.rf_sensor1_id === v.rf_sensor1_id ||
          this.detectionObject.rf_sensor2_id === v.rf_sensor2_id
        ) {
          this.tail.push([v.latitude, v.longitude])
          this.detectionObject = v
        }
      }
    }
  },
  mounted() {
    this.tail.push([this.detection.latitude, this.detection.longitude])
  }
}
</script>
